"use strict";

// polyfill to enable us to use forEach on node lists in IE11
const ForEachPolyfill = require("./modules/foreach-polyfill");
ForEachPolyfill.init();

// interpolate between minimum ratio & maximum ratio, based on current viewport width
const TypeRatioInterpolation = require("./modules/type-ratio-interpolation");
TypeRatioInterpolation.init();

// detect focus source using a11y.js
const FocusAccessibility = require("./modules/focus-accessibility");
FocusAccessibility.init();

// behaviour to provide an accessible global navigation
const GlobalNavigation = require("./modules/global-navigation");
GlobalNavigation.init();

// behaviour to display and hide the services modal content
const ServicesModalContent = require("./modules/services-modal-content");
ServicesModalContent.init();
