module.exports = {
    // behaviour to display and hide the services modal content
    init: () => {
        // store classnames for modal content
        const modalContentClass   = "services_child_pages__content";
        const modalVisibleClass = "modal_is_visible";
        // store all buttons which will open a services modal
        const openButtons = Array.from(document.getElementsByClassName("services_child_pages__button"));
        if (openButtons) {
            // loop over these buttons
            openButtons.forEach((openButton) => {
                // on click of this button
                openButton.addEventListener(
                    "click",
                    () => {
                        // get this button's parent element
                        const parentElement = openButton.parentElement;
                        // get this button's corresponding modal content
                        const modalContent = parentElement.querySelector(`.${modalContentClass}`);
                        if (modalContent) {
                            // add display class to this button's corresponding modal content
                            modalContent.classList.add(`${modalContentClass}--is_visible`);
                            // add modal display class from body element
                            document.body.classList.add(modalVisibleClass);
                        }
                    },
                    false,
                );
            });
        }
        // store all buttons which will open a services modal
        const closeButtons = Array.from(document.getElementsByClassName(`${modalContentClass}__close`));
        if (closeButtons) {
            // loop over these buttons
            closeButtons.forEach((closeButton) => {
                // on click of this button
                closeButton.addEventListener(
                    "click",
                    () => {
                        // get this button's parent element
                        const parentElement = closeButton.parentElement;
                        // remove display class from this button's corresponding modal content
                        parentElement.classList.remove(`${modalContentClass}--is_visible`);
                        // remove modal display class from body element
                        document.body.classList.remove(modalVisibleClass);
                    },
                    false,
                );
            });
        }
    },
};
