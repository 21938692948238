module.exports = {
    // behaviour to provide an accessible global navigation
    init: () => {
        // allow user to open the global navigation on interaction with
        // the relevant button element in the global header
        /* global ally */
        const navigationOpenButtonClass  = ".global_header__navigation_button";
        const navigationOpenButton       = document.querySelector(navigationOpenButtonClass);
        const focusConstrainElementClass = ".global_header";
        const focusConstrainElement      = document.querySelector(focusConstrainElementClass);
        const navigationElementClass     = ".global_navigation";
        const navigationElement          = document.querySelector(navigationElementClass);
        const htmlElement                = document.documentElement;
        const navigationIsOpenClass      = "navigation_is_open";
        // define disabledHandle to allow us to use a11y.js
        // to limit focus scope to a specific element
        let disabledHandle;
        navigationOpenButton.addEventListener("click", function () {
            htmlElement.classList.toggle(navigationIsOpenClass);
            const open = JSON.parse(navigationOpenButton.getAttribute("aria-expanded"));
            navigationOpenButton.setAttribute("aria-expanded", !open);
            navigationElement.hidden = !navigationElement.hidden;
            if (!open) {
                // constrain focus to the navigation
                /* eslint-disable no-unused-vars */
                disabledHandle = ally.maintain.disabled({
                    filter: focusConstrainElement,
                });
                /* eslint-enable no-unused-vars */
            } else {
                // allow focus outside of the navigation again
                disabledHandle.disengage();
            }
        });
    },
};
